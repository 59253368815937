<template>
    <div style="padding-top: 0.9rem;">
        <div class="Tabspic">
            <Tabs v-model="activeName" title-active-color="#000">
                <tab title="素材" name="0"></tab>
                <tab title="广告宣传" name="1"></tab>
                <tab title="食物搭配" name="2"></tab>
                <tab title="证书" name="3"></tab>
                <tab title="仓库" name="4"></tab>
                <tab title="包装" name="5"></tab>
            </Tabs>
        </div>
        <List v-model="loading" :finished="finished" finished-text="没有更多了~" @load="onLoad">
            <div class="piclist">
                <div class="picitem" v-for="item in list" :key="index" @click="ImgPreview">
                    <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                </div>
            </div>
        </List>
    </div>
</template>
<script>
import { List, Cell, ImagePreview, Tab, Tabs } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "piclist",
    data: function() {
        return {
            activeName:'0',
            list: [],
            loading: false,
            finished: false,
        };
    },
    components: {
        List, Cell, VanImage, ImagePreview, Tab, Tabs
    },
    created: function() {
        if(this.$route.query.tab){
            this.activeName=this.$route.query.tab;
        }
    },
    mounted: function() {
        // document.title = "订单创建成功";
    },
    methods: {
        ImgPreview() {
            ImagePreview({
                images: [
                    'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                ],
                startPosition: 0,
            });
        },
        onLoad() {
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        setTimeout(() => {
            for (let i = 0; i < 10; i++) {
            this.list.push(this.list.length + 1);
            }

            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.list.length >= 40) {
            this.finished = true;
            }
        }, 1000);
        },
    }
};
</script>
<style scoped lang="scss">
    .Tabspic{
        position: fixed;z-index: 1;top: 0;left: 0;right: 0;border-bottom:1px solid #eee;
    }
    .piclist{
        padding: 0.05rem;overflow:hidden;
        .picitem{
            float:left;width: 25%;height:2.6rem;padding: 0.05rem;
        }
    }
</style>